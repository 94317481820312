import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player'

import Button from '../Button'

import * as S from './styles'

import { lottieConfig } from '../../utils'

import iconTwitter from '../../images/twitter.svg'
import iconLinkedin from '../../images/linkedin.svg'
import iconInstagram from '../../images/instagram.svg'

const Footer = () => {
  const [animationData, setAnimationData] = useState()

  useEffect(() => {
    import('../../assets/lottie/logo.json').then(setAnimationData)
  }, [])

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.LogoWrapper>
            {animationData && (
              <Lottie
                {...lottieConfig({ animationData })}
                style={{ height: 32, width: 120 }}
              />
            )}
          </S.LogoWrapper>
          <S.Text>
            We're currently open to new projects, would you like to discuss
            yours?
          </S.Text>
          <Button href="https://calendly.com/gnar/15min-dp" target="_blank">
            Schedule a meeting
          </Button>

          <S.Text small>
            {`© ${new Date().toLocaleDateString('en-us', {
              year: 'numeric',
            })} GNAR LLC`}
          </S.Text>
          <S.Text small mono>
            All icons are from the noun project.
          </S.Text>
        </S.Content>
        <S.Content>
          <S.SocialWrapper>
            <a
              href="https://www.linkedin.com/company/gnar1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SocialIcon src={iconLinkedin} />
            </a>
            <a
              href="https://twitter.com/gnarllc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SocialIcon src={iconTwitter} />
            </a>
            <a
              href="https://www.instagram.com/gnar.llc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SocialIcon src={iconInstagram} />
            </a>
          </S.SocialWrapper>
          <S.Anchor href="mailto:contact@gnar.io">contact@gnar.io</S.Anchor>
          <S.Text>San Francisco, California</S.Text>
          <S.Text>415.418.9496</S.Text>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

export default Footer
