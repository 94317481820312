import React from 'react'

import { Layout, SEO, CaseStudy, Showcase, Iframe } from '../components'

import socialCard from '../images/social_card_home.png'

const Intrmodl = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Slingshot"
      image={socialCard}
      description="One app for collaborative task management with you and your stakeholders."
    />
    <CaseStudy
      projectName="Slingshot"
      descriptionTitle="The Problem"
      description="You must maintain a slew of Excel sheets with multiple
      clients while juggling calls and emails to sync project status under a looming deadline."
      svg="problem"
      showcase
    />
    <Showcase
      title="Webapp"
      titleDescription="THE SOLUTION"
      description="Use 1 CENTRALIZED location consolidating all the TODO's and project requirements between you and your stakeholders. Now, you can deliver projects faster and see any project status quickly."
      svg="dashboard"
      direction="normal"
    />
    <Iframe url="https://www.youtube.com/embed/ULcwD6dOKeQ" /> 
    <Showcase
      titleDescription="THE SOLUTION"
      title="Focused project updates"
      description="Easy overview of project updates for each vendor relationship and indicators for tasks needed for your attention."
      svg="projects"
      direction="inverted"
      halfSizeImage={true}
    />
    <Showcase
      titleDescription="THE SOLUTION"
      title="Visualized status & priority"
      description="Customized coloring to fit your teams' workflow, where you can change status or priority, automatically alerting the assigned stakeholder."
      svg="status"
      direction="normal"
      halfSizeImage={true}
    />
    <Showcase
      titleDescription="THE SOLUTION"
      title="Outgoing reminders"
      description="Any slingshot task can send out an email reminder, when they click to respond, the status update is saved into slingshot, you stay organized and they don't miss tasks and deadlines."
      svg="reminders"
      direction="inverted"
    />
    {/* <div
      style={{
        textAlign: 'center',
        margin: '0 auto',
        padding: '40px 0',
        width: 'fit-content',
      }}
    >
      <p style={{ fontSize: 26, marginBottom: 20 }}>Sign Up Now!</p>
      <a
        href="https://slingshot-inc.com"
        target="_blank"
        style={{
          textDecoration: 'none',
          fontSize: 16,
          color: '#FFF',
          padding: '20px 40px',
          display: 'block',
          width: 'fit-content',
          background: 'transparent',
          border: '2px solid #FFF',
          margin: '0 auto',
        }}
      >
        TRY NOW
      </a>
    </div> */}
  </Layout>
)

export default Intrmodl
